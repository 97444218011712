import React from 'react'
import Table from './common/Table'
import CustomerTableItem from './CustomerTableItem'

export default function CustomerTable({ statuses, statsObj, onUpdate, customers, type }) {
    return (
        <Table
            style={{ paddingBottom: 450 }}
            showEmptyMessage={!customers.loading}
            columns={{
                index: '№',
                name: 'Имя',
                status: 'Состояние',
                groups: 'Группы',
                subjects: 'Предметы',
                teacher: 'Учитель',
                createdAt: 'Дата',
                actions: '',
            }}
            loading={customers.loading}
            items={customers.response ? customers.response.results : []}
            renderItem={(item, index) => (
                <CustomerTableItem
                    index={index}
                    key={item.id}
                    customer={item}
                    onDelete={customers.reload}
                    onUpdate={onUpdate}
                    status={statsObj[item.state]}
                    showLabels={type === ''}
                    statusList={statuses}
                    type={type} />
            )}
            children={(
                <div ref={customers.ref} className="has-text-grey-light is-italic has-text-centered">
                    {!customers.hasMore && !customers.loading && customers.length !== 0 ? 'Загрузили всех студентов' : ''}
                </div>
            )}
        />
    )
}
