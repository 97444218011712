import React, { useEffect } from 'react'
import find from 'lodash/find'
import { Formik, Form } from 'formik'
import { useLoad, useGetRequest } from '../hooks/request'
import { TRANSACTION_CATEGORY_LIST, SALES_CHANNEL_LIST, EMPLOYEE_LIST } from '../urls'
import { required } from '../utils/validators'
import Button from './common/Button'
import Input from './common/Input'
import Select from './common/Select'


export default function TransactionForm({ loading, onSubmit, onCancel, values, type }) {
    const initialValues = { amount: '', category: '', description: '', advert: '', employee: '', ...values }

    const categories = useLoad({
        params: { type },
        url: TRANSACTION_CATEGORY_LIST,
    })

    const adverts = useGetRequest({ url: SALES_CHANNEL_LIST })
    const employee = useGetRequest({ url: EMPLOYEE_LIST })

    useEffect(() => {
        if (values ? values.advert : false) adverts.request()
        if (values ? values.employee : false) employee.request()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const marketingCategory = find(
        categories.response ? categories.response.results : [],
        { systemName: 'advert' },
    ) || {}

    const salaryCategory = find(
        categories.response ? categories.response.results : [],
        { systemName: 'salary' },
    ) || {}

    function onCategoryChange(categoryId, setFieldValue) {
        // get category by id
        const category = find(categories.response.results, { id: parseInt(categoryId, 10) })
        // autofill form values
        if (marketingCategory.id === Number(categoryId)) adverts.request()
        if (salaryCategory.id === Number(categoryId)) employee.request()
        setFieldValue('category', categoryId)
        if (category.defaultAmount) { setFieldValue('amount', category.defaultAmount) }
        if (category.descriptionTemplate) { setFieldValue('description', category.descriptionTemplate) }
    }

    function handleSubmit(data) {
        let amount = Math.abs(data.amount)
        if (type === 'outcome') {
            amount *= -1
        }

        onSubmit({ ...data, amount })
    }

    function onChangeAdvert(value, setFieldValue) {
        setFieldValue('advert', value)
        if (value === '') return
        const advert = adverts.response ? find(
            adverts.response.results,
            { id: Number(value) },
        ) : {}
        setFieldValue('description', `Оплата за рекламу, канал: ${advert.name || ''}.`)
    }

    function onChangeEmployee(value, setFieldValue) {
        setFieldValue('employee', value)
        if (value === '') return
        const pickedEmployee = employee.response ? find(
            employee.response.results,
            { id: Number(value) },
        ) : {}
        setFieldValue('description', `Зарплата сотрудника ${pickedEmployee.name}.`)
    }

    return (
        <Formik onSubmit={handleSubmit} initialValues={initialValues}>
            {({ setFieldValue, values: { category } }) => (
                <Form>
                    <div className="field">
                        <div className="field-body">
                            <Select
                                name="category"
                                options={categories.response ? categories.response.results : []}
                                label="Категория"
                                help="Вы можете добавить новую категорию в настройках"
                                empty
                                loading={categories.loading}
                                onChange={({ target }) => onCategoryChange(target.value, setFieldValue)}
                                validate={required} />

                            {marketingCategory && marketingCategory.id === Number(category) ? (
                                <Select
                                    empty
                                    name="advert"
                                    label="Канал продаж"
                                    loading={adverts.loading}
                                    options={adverts.response ? adverts.response.results : []}
                                    help="Канал продаж можно добавить в настройках"
                                    onChange={({ target }) => onChangeAdvert(target.value, setFieldValue)}
                                    validate={required} />
                            ) : null}

                            {salaryCategory && salaryCategory.id === Number(category) ? (
                                <Select
                                    empty
                                    name="employee"
                                    label="Сотрудник"
                                    loading={employee.loading}
                                    options={employee.response ? employee.response.results : []}
                                    help="Вы можете добавить нового сотрудника в меню Персонал"
                                    onChange={({ target }) => onChangeEmployee(target.value, setFieldValue)}
                                    validate={required} />
                            ) : null}
                        </div>
                    </div>

                    <Input name="amount" type="number" label="Сумма" validate={required} />
                    <Input name="description" component="textarea" label="Описание" validate={required} />

                    <Button
                        loading={loading}
                        text="Сохранить"
                        type="submit"
                        icon="ion-md-checkmark"
                        className="is-success" /> &nbsp;

                    <Button
                        onClick={onCancel}
                        icon="ion-md-close"
                        text="Отмена"
                        className="is-danger" />
                </Form>
            )}
        </Formik>
    )
}
