import { Form, Formik } from 'formik'
import React from 'react'
import { required } from '../utils/validators'
import { EMPLOYEE_SIMPLE_LIST, ROOM_LIST, SUBJECT_LIST } from '../urls'
import Input from './common/Input'
import { useLoad } from '../hooks/request'
import Button from './common/Button'
import Select from './common/Select'


export default function GroupForm({ onSubmit, onCancel, loading, initialValues }) {
    const employee = useLoad({ url: EMPLOYEE_SIMPLE_LIST, params: { position: 'teacher', size: 1000 } })
    const subjects = useLoad({
        url: SUBJECT_LIST, params: { size: 1000 },
    })
    const room = useLoad({ url: ROOM_LIST })

    const values = {
        name: '',
        price: '',
        teacher: '',
        subject: '',
        paymentPrice: '',
        lessonCount: '',
        ...initialValues,
    }

    return (
        <Formik initialValues={values} onSubmit={onSubmit}>
            <Form>
                <Input
                    name="name"
                    disabled={loading}
                    validate={required}
                    label="Название"
                    placeholder="Введите имя группы" />

                <Select
                    name="teacher"
                    options={employee.response ? employee.response.results : []}
                    label="Учитель"
                    loading={employee.loading}
                    help="Вы можете добавить нового учителя в меню персонал"
                    validate={required} />
                <Select
                    name="subject"
                    options={subjects.response ? subjects.response.results : []}
                    label="Предметы"
                    loading={employee.loading}
                    help="Вы можете добавить нового предметы в меню персонал"
                    validate={required} />

                <Select
                    name="room"
                    options={room.response ? room.response.results : []}
                    label="Кабинет"
                    loading={room.loading}
                    help="Вы можете добавить новый кабинет в настройках"
                    validate={required} />

                <Input
                    name="price"
                    disabled={loading}
                    validate={required}
                    type="number"
                    label="Стоимость абонемента"
                    placeholder="Введите сумму оплаты" />

                <Input
                    name="paymentPrice"
                    type="number"
                    label="Доля учителя"
                    disabled={loading}
                    validate={required}
                    placeholder="Введите долю учителя" />
                <Input
                    name="lessonCount"
                    type="number"
                    label="Количество уроков"
                    disabled={loading}
                    validate={required}
                    placeholder="Введите количество уроков" />

                <Button
                    loading={loading}
                    text="Сохранить"
                    type="submit"
                    icon="ion-md-checkmark"
                    className="is-success" /> &nbsp;

                <Button
                    onClick={onCancel}
                    icon="ion-md-close"
                    text="Отмена"
                    className="is-danger" />
            </Form>
        </Formik>
    )
}
