/* eslint-disable no-nested-ternary */
import React from 'react'
import moment from 'moment'
import { checkPermission as cp } from '../utils/auth'
import { useDeleteRequest } from '../hooks/request'
import { TRANSACTION_DETAIL } from '../urls'
import { useModal } from '../hooks/modal'
import Loader from './common/Loader'
import { format } from '../utils/number'
import TransactionUpdate from './TransactionUpdate'

export default function TransactionItem({ transaction, walletId, reload }) {
    const transactionDelete = useDeleteRequest({ url: TRANSACTION_DETAIL.replace('{id}', transaction.id) })

    async function deleteTransaction() {
        if (global.confirm('Вы действительно хотите удалить?')) {
            await transactionDelete.request()
            reload()
        }
    }

    const [showUpdateModal, hideUpdateModal] = useModal(
        <TransactionUpdate
            wallet={walletId}
            values={transaction}
            onCancel={() => hideUpdateModal()}
            onSuccess={async () => {
                await reload()
                hideUpdateModal()
            }} />,
    )

    return (
        <tr>
            <td>{transaction.description || <span className="is-italic has-text-grey">не указан</span>}</td>

            <td className={transaction.amount > 0 ? 'has-text-success' : 'has-text-danger'}>
                <b>{format(transaction.amount) || <span className="is-italic has-text-grey">не указан</span>}</b>
            </td>

            <td>
                {transaction.createdBy.firstName
                    || transaction.createdBy.email
                    || <span className="is-italic has-text-grey">не указан</span>}
            </td>
            <td>
                {transaction.category ? transaction?.category?.name :
                    <span className="is-italic has-text-grey">не указан</span>
                }
            </td>

            <td>
                {moment(transaction.createdAt).calendar()
                || <span className="is-italic has-text-grey">не указан</span>}
            </td>

            <td>
                {cp('finance.delete_transaction') ? (
                    !transactionDelete.loading ? (
                        <i onClick={() => deleteTransaction()} className="icon pointer ion-md-trash" />
                    ) : <Loader className="icon" />
                ) : null}

                {cp('finance.change_transaction') ? (
                    <i onClick={() => showUpdateModal()} className="icon pointer ion-md-create" />
                ) : null}
            </td>
        </tr>
    )
}
