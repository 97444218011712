import React from 'react'
import { Form, Formik } from 'formik'
import { useLoad, usePostRequest } from '../hooks/request'
import { PAYMENT_STUDENT, WALLET_SIMPLE_LIST } from '../urls'
import Select from '../components/common/Select'
import Input from '../components/common/Input'
import Button from '../components/common/Button'
import { required } from '../utils/validators'

export default function CustomerPaymentCreate({ customer, onSuccess }) {
    const wallets = useLoad({ url: WALLET_SIMPLE_LIST })
    const transactionCreate = usePostRequest()
    async function onSubmit(data, actions) {
        await transactionCreate.request({
            data,
            url: PAYMENT_STUDENT.replace('{id}', data.student),
        })
        actions.resetForm()
        onSuccess()
    }


    return (
        <Formik onSubmit={onSubmit} initialValues={{ amount: '', wallet: '', student: '' }}>
            <Form>
                <p style={{ fontSize: '20px', fontWeight: 500, marginBottom: '1rem' }}>Добавить оплату</p>
                <Select
                    name="student"
                    options={customer.students}
                    optionLabel={(item) => item.group.name}
                    validate={required} />
                <Input
                    name="amount"
                    type="number"
                    placeholder="Сумма"
                    validate={required} />

                <Select
                    name="wallet"
                    help="Вы можете добавить новый счёт в настройках"
                    options={wallets.response || []}
                    loading={wallets.loading}
                    validate={required} />

                <Button
                    loading={transactionCreate.loading}
                    text="Добавить"
                    type="submit"
                    icon="ion-md-add"
                    className="is-info" />
            </Form>
        </Formik>
    )
}
